<template>
  <div>
    <Permission :overwrite="['create', 'read', 'update']"/>
    <Breadcrumb/>
    <div class="title pb-4">New Entity</div>
    <Form @onSave="createCorporation">
      <template v-slot:bottom v-if="isLoading">
        <b-button type="is-blue" disabled>Save</b-button>
      </template>
    </Form>
  </div>
</template>
<script>
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Form from './Form.vue'
import { createCorporation as createCorp } from '@api/addy-plus/corporations'
import { camelToSnakeCase } from '@utils/common-methods/common'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default {
  title: 'New Corporation',
  mixins: [pageTitleMixin],
  components: {
    Permission,
    Form,
    Breadcrumb,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
  },
  methods: {
    createCorporation(params) {
      this.isLoading = true
      const payload = {
        ...this.parseParams(params),
        corp_country: params.address.country, // required field on Corporation model
        addy_plus_account_id: this.accountId
      }
      createCorp(payload).then((res) => {
        if (!res.success) return
        this.$router.push(`/addyPlus/${this.accountId}/corporations`)
      }).finally(() => {
        this.isLoading = false
      })
    },
    parseParams(payload) {
      const obj = {}
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          obj[camelToSnakeCase(key)] = key === 'address' ? this.parseParams(payload[key]) : payload[key]
        }
      }
      return obj
    },
  },
}
</script>
